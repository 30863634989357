.pref-collapser {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.preference-collapse-new {
  /* width: 220px; */
  height: 37px;
  border-radius: 6px;
  /* background-color: #2e6ad2; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.pref-legend-div {
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  background: #dcedff;
}

.pref-legend-div-1 {
  display: flex;
  gap: 10px;
}

.pref-legend-div-1 span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 16.8px */
}

.pref-legend-div-2 {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  gap: 5px;
}

.pref-legend-div-2 span {
  color: #2e6ad2;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 16.8px */
  text-decoration-line: underline;
  cursor: pointer;
}

.pref-legend-div-1 span:first-child {
  color: #555;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.pref-collapse-header-new,
.pref-collapse-header-new-2 {
  margin-top: 8px;
  margin-left: 10px;
  color: var(--232D42-Text-Color-1, #232d42);
  font-family: "Source Sans Pro";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.pref-collapse-header-new-2 {
  color: #555555;
}

.pref-collapse-list-new {
  cursor: pointer;
  max-height: 70vh;
}

.pref-collapser-item-new {
  margin-top: 15px;
}

.pref-collapser-item-new:first-child {
  margin-top: 0px;
}

.pref-collapser-item-new-tab {
  padding: 10px 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
}

.pref-collapser-item-new-tab span {
  font-family: "Source Sans Pro";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 100%;
  /* 14px */
}

.pref-collapser-item-tab-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pref-item-tab-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 200px;
  overflow: scroll;
}

.pref-item-tab-div .coming-soon-text {
  display: flex;
  justify-content: center !important;
  width: 100%;
  font-family: "Source Sans Pro";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 100%;
  /* 14px */
}

.pref-item-tab-div-data {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: flex-start;
  gap: 10px;
}

.pref-item-tab-div-data span {
  color: #555;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.preference-collapse-new-checkbox {
  display: flex;
  /* gap: 5px; */
  align-items: center;
  padding-left: 10px;
}

.pref-toggle-switch input:checked + .pref-slider {
  background-color: #8e98a8;
}

.pref-toggle-switch input:checked + .pref-slider:before {
  transform: translateX(-15px);
}

.pref-toggle-switch input:not(:checked) + .pref-slider {
  background-color: #2e6ad2;
}

.pref-toggle-switch input:not(:checked) + .pref-slider:before {
  transform: translateX(0);
}

.pref-toggle-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  margin-bottom: 0px !important;
}

.pref-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pref-slider,
.status-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2e6ad2;
  transition: 0.4s;
  border-radius: 20px;
}

.pref-slider:before,
.status-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2.8px;
  bottom: 2.5px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 0.4s;
}

.pref-slider:before {
  left: 18px;
}

.notification-modal .modal-content {
  width: 500px !important;
}

.notification-modal .pref-modal-content h3 {
  color: var(--navy-blue, #2c3957);
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
  /* 35px */
}

.notification-modal .pref-modal-content p {
  color: var(--primary-text, #555) !important;
  text-align: center !important;
  font-family: "Source Sans Pro" !important;
  font-size: 12.64px !important;
  font-style: normal !important;
  font-weight: 600;
  line-height: normal !important;
  margin-top: 0px !important;
}

.notification-modal .classic-notification {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-modal .classic-notification span {
  color: #555;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.no-data-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.no-data-div .text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}

.no-data-div .text span:first-child {
  width: 100%;
  color: #555;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.no-data-div .text span {
  color: #858c98;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.headerDivider {
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  height: 35px;
}
