.preview-main-div {
  margin-top: 20px;
}

.chart-div {
  margin-top: 20px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border: 1px solid #dcedff;
}

.div-1 {
  display: flex;
  justify-content: space-between;
}
.div-1 div {
  display: flex;
  gap: 10px;
}

.save-btn {
  border-radius: 5px;
  background: #046cd9;
  display: flex;
  height: 30px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--color-gray-10, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.div-1 input {
  color: #606b77;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  display: flex;
  width: 304px;
  height: 30px;
  padding: 4px 10px;
  align-items: center;
  gap: 130px;
}
.preview-main-div .div-1 span {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.view-toggle {
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2px;
  border: 1px solid var(--stroke, #e9ebed);
  background: var(--White, #fff);
  height: auto;
  margin-left: 20px;
}

.view-toggle button {
  height: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  padding: 0px 10px !important;
  width: 100px;
}
.active-btn {
  border-radius: 2px;
  background: var(--bright_blue, #2e6ad2);
  transition: none !important;
  box-shadow: none;
}
.not-active-btn {
  border-radius: 2px;
  background: #fff;
  color: var(--Unnamed-Color, #003870);
  transition: none !important;
  box-shadow: none;
}

input:checked + .slider {
  background-color: #1e81e8;
}

.visalization-slider:before {
  // left: 18px;
  background-color: #ffffff;
}

.visalization-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  // border: 2px solid #84909e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.visalization-slider.round {
  border-radius: 34px;
}

.visalization-slider.round:before {
  border-radius: 50%;
}
.visalization-slider,
.status-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e81e8;
  transition: 0.4s;
  border-radius: 20px;
}

.visalization-slider:before,
.status-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2.8px;
  bottom: 2.5px;
  border-radius: 50%;
  background-color: #ffffff;
  // background-color: #0c2641;
  transition: 0.4s;
}

.chart-dropdown {
  width: 150px;
}

.q-slider:before {
  left: 33.5px !important;
  background-color: #ffffff;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  gap: 20px;
}

.chart-container {
  width: 100%;
  height: 280px;
  border-bottom: 1px solid #dcedff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // position: relative; /* Ensure position is relative for correct rendering */
}

// .chart-container svg {
//   position: static !important; /* Remove absolute positioning */
//   user-select: none !important;
// }

.save-button {
  height: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.custom-slider {
  z-index: 13;
  // width: 100%;
  position: absolute;
  right: 10%;
  top: 100%;
  min-width: 500px;
  height: 120px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border-top: 5px solid #046cd9;
  background: var(--color-gray-10, #fff);
  box-shadow: 3px 0px 50px 0px rgba(0, 0, 0, 0.1);
  // border: 2px solid black;
  gap: 36px;
  padding: 0px 20px;
  border-bottom: 5px solid #046cd9;
  .rc-slider-track {
    height: 5px !important;
    background-color: #046cd9;
  }
  .rc-slider-dot-active {
    border-color: #046cd9;
  }
  .rc-slider-handle {
    height: 20px;
    width: 20px;
    margin-top: -9px;
    border: solid 2px #046cd9;
  }
}

.slider-input {
  display: flex;
  width: 150px;
  height: 35px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #046cd9;
  background: var(--color-gray-10, #fff);
  color: #046cd9;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  // margin-right: 20px;
}

.custom-slider .slider-div {
  padding: 0px 10px;
  display: flex;
  margin-top: 20px;
  .rc-slider-mark-text {
    transform: rotate(90deg) !important; /* Rotate the mark label */
    white-space: nowrap; /* Prevent the label from wrapping */
    text-align: left; /* Adjust alignment */
    margin-bottom: 10px; /* Adjust space between mark and slider */
  }
}

.metrics-dropdown-list .rw-widget-picker {
  border: none;
  border-radius: 0;
}
.rule-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: -webkit-fill-available;
  padding: 10px;
}
.rules-item-div {
  display: inline-flex;
  justify-content: space-between;
  padding: 5px 7px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: var(--bright_blue, #2e6ad2);
  color: #fff;
  // font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.visalization-input:disabled {
  background-color: inherit;
}
.metrics_dropdown .rw-state-disabled > .rw-widget-picker {
  background-color: transparent !important;
}

.select_companies_search .form-control:disabled {
  background-color: rgba(243, 243, 243, 0.5) !important;
}

.range-button {
  display: flex;
  width: auto;
  height: 35px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #046cd9;
  background: var(--color-gray-10, #fff);
  color: #046cd9;
  // font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 200% */
}

.slider-save-btn {
  display: inline-flex;
  height: 30px;
  width: 50px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #046cd9;
  border: none;
  color: #fff;
  right: 10px;
  position: absolute;
  top: 70px;
}

.rc-slider-tooltip {
  z-index: 13; /* Adjust this value as needed */
}

.downlaod-dropdown {
  border-radius: 5px;
  border-top: 5px solid #046cd9;
  background: var(--color-gray-10, #fff);
  box-shadow: 3px 0px 50px 0px rgba(0, 0, 0, 0.1);
  // border: 2px solid black;
  // gap: 36px;
  padding: 10px 0px;
  border-bottom: 5px solid #046cd9;
}

.download-format-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-format-btn:hover {
  background: #046cd9;
  color: #ffffff;
  cursor: pointer;
}
.no_data {
  width: inherit;
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}
