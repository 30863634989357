.justify-content-space-between {
  justify-content: space-between;
}
.main_div {
  padding: 20px 25px !important;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.f-23 {
  font-size: 23px;
}
.fw-700 {
  font-weight: 700;
}
.g-10 {
  gap: 10px;
}
.flex-d {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.h-30vh {
  height: 30vh;
}
.align-item-center {
  align-items: center;
}
.modal-lg {
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 576px;
      margin-top: 50px;
    }
  }
}
.category-title {
  color: #283959;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}

.title-text {
  color: #046cd9;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
}

.dropdown-list {
  .rw-widget-container {
    border-radius: 5px;
    border: 1px solid #e4e4e4;
  }
  .rw-widget-input {
    box-shadow: none;
  }
  .rw-placeholder {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  min-height: 200px;
  gap: 16px;
  padding: 15px 0px 0px 0px;
}

.grid-container > div {
  // background-color: lightgrey;
  padding: 15px;
  text-align: center;
}
.grid-item-resources {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background: var(--color-gray-10, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.09);
  position: relative;
  .thumbnail {
    height: 120px;
    width: 100%;
    border: 1px solid #e7e9ed;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    opacity: 0.6;
  }
  .item-title {
    color: #161c2d;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    // line-height: 32px; /* 177.778% */
    letter-spacing: -0.5px;
    text-align: left;
  }
}
.play-button {
  position: absolute;
  top: 25%;
  left: 40%;
  z-index: 10;
}
.youtube-player {
  border-radius: 10px;
  border: 1px solid #c9c7c7;
}
.faq-collapse {
  width: 100%;
  height: 36px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  align-items: center;
  gap: 32px;
  align-self: stretch;
  color: #000;
  .faq-collapse-header {
    gap: 32px;
    .span_1 {
      color: var(--Text-txt-tertiary, rgba(60, 60, 67, 0.5));
      font-family: Inter;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 50.4px */
      letter-spacing: 0.25px;
    }
    .span_2 {
      color: var(--Text-txt-primary, #000);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 21.6px */
    }
  }
}

.faq-collapse-list {
  color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  align-self: stretch;
  margin-left: 75px;
  p {
    color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
    img {
      width: 100%;
    }
  }
}
