//Variable
$clr_error: red;
$clr_white: #fff;
$clr_lilywhite: #e8f6ef;
$clr_black: #000;
$clr_black2: #0b2540;
$clr_bodycolor: #e9eef5;
$clr_grey: #99a0aa;
$clr_grey2: #9796b5;
$clr_grey3: #626262;
$clr_darkgrey: #333;
$clr_darkgrey2: #1c1c1c;
$clr_lightgrey: #606b77;
$clr_lightgrey_new: #e9ebee;
$clr_lightgrey2: #84909e;
$clr_lightgrey3: #f4f5f8;
$clr_lightgrey4: #e2e8f2;
$clr_lightgrey5: #707070;
$clr_lightgrey6: #f4f9f9;
$clr_lightgrey7: #767b84;
$clr_lightgrey8: #e2e2e2;
$clr_lightgrey9: #f1f1f1;
$clr_lightblue: #1085c6;
$clr_lightblue2: #046cd9;
$clr_lightblue3: #4d88c9;
$clr_lightblue4: #1e81e8;
$clr_lightblue5: #6eaef0;
$clr_tooltip: #0079f5;
$clr_solitude: #dcedff;
$clr_solitude2: #e4ecf3;
$clr_darkblue: #052240;
$clr_darkblue2: #06305c;
$clr_darkblue3: #3a6c9f;
$clr_darkblue4: #08396c;
$clr_darkblue5: #004dc2;
$clr_darkblue6: #283959;
$clr_green: #1d6e3b;
$clr_border: #cfd4d9;
$clr_tableborder: #bed4eb;
$clr_tableborder_chart: #dcedff;
$clr_green2: #02bf48;
$clr_green3: #00894d;
$clr_lightgreen: #b7ffe0;
$clr_lightgreen2: #307153;
$clr_red: #ff5a5a;
$clr_red2: #ff312b;
$clr_red3: #ee3535;
$clr_orange: #f99725;
$clr_orange2: #e88a1e;
$clr_lightorange: #fff1e1;
$clr_blue: #0461c2;
$clr_bluelink: #094d96;
$clr_blue_tooltip: #07407a;
$clr_baby_blue: #edf6ff;
$clr_alice_blue: #eff6fc;
$clr_alice_blue2: #f1f8fe;
$clr_alice_blue3: #eef4f9;
$clr_cornflower_blue: #464971;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

//Transforms

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
@mixin translateY($y) {
  @include transform(translateY($y));
}
// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin calc($property, $expression) {
  #{$property}: -o-calc(#{$expression});
  #{$property}: -ms-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

//Implimenting
header_scss {
  background: $clr_error;
  //flex
  @include flexbox;
  @include flex(1);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include flex-flow(row-reverse wrap);
  @include order(1);
  @include flex-grow(2);
  @include flex-shrink(1);
  @include flex-basis(100px);
  @include justify-content(center);
  @include align-content(center);
  @include align-items(center);
  @include align-self(auto);
  //Transitions
  @include transition(all 0.5s ease-in-out);
  @include transition-property(background);
  @include transition-duration(0.5s);
  @include transition-timing-function(ease-in-out);
  @include transition-delay(2s);
  //Transforms
  @include rotate(360);
  @include skew(45, 10);
  @include scale(1);
  @include translate(10px, 30px);
  @include translateY(50px);
  //Width
  @include calc(width, "100% - 2px");
}

//Compile to css
header_scss {
  background: red;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row-reverse wrap;
  -moz-flex-flow: row-reverse wrap;
  -ms-flex-flow: row-reverse wrap;
  flex-flow: row-reverse wrap;
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  -webkit-flex-grow: 2;
  -moz-flex-grow: 2;
  -ms-flex-grow: 2;
  flex-grow: 2;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100px;
  -moz-flex-basis: 100px;
  -ms-flex-basis: 100px;
  flex-basis: 100px;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-self: auto;
  -moz-align-self: auto;
  -ms-align-self: auto;
  align-self: auto;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition-property: background;
  -o-transition-property: background;
  -webkit-transition-property: background;
  transition-property: background;
  -moz-transition-property: 0.5s;
  -o-transition-property: 0.5s;
  -webkit-transition-property: 0.5s;
  transition-property: 0.5s;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -moz-transition-delay: 2s;
  -o-transition-delay: 2s;
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -moz-transform: skew(45deg, 10deg);
  -o-transform: skew(45deg, 10deg);
  -ms-transform: skew(45deg, 10deg);
  -webkit-transform: skew(45deg, 10deg);
  transform: skew(45deg, 10deg);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -moz-transform: translate(10px, 30px);
  -o-transform: translate(10px, 30px);
  -ms-transform: translate(10px, 30px);
  -webkit-transform: translate(10px, 30px);
  transform: translate(10px, 30px);
  -moz-transform: translateY(50px);
  -o-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  width: -o-calc(100% - 2px);
  width: -ms-calc(100% - 2px);
  width: -moz-calc(100% - 2px);
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
}
