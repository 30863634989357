.rating-header {
  width: 450px;
}

.rating-form {
  .modal-body {
    padding: 15px 0px 0px !important;
  }

  .modal-content {
    border-radius: 3.5px;
    border-top: 12px solid #4d88c9 !important;
    border-bottom: 12px solid #4d88c9 !important;
    padding: 10px 25px 25px;
  }
}

.rating-body {
  .rating-ques {
    color: #555555;
    font-weight: 600;
  }

  .custom-border {
    border-color: #3f7fe0 !important;
  }
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox input[type="checkbox"] + label {
  cursor: pointer;
  color: #84909e;
  font-weight: 600;
}

.custom-checkbox input[type="checkbox"]:checked + label {
  background-color: #1dac74;
  color: #fff;
}

.user-details-block {
  font-size: 14px;
  margin: 0px 6px 0px 8px;
}

.star-rating {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  .on {
    color: #f7bc25;
  }

  .off {
    color: #ccc;
  }
}

.feedback-form-ineer-body {
  font-size: 14px !important;
  .seperator-line {
    border-bottom: 1px solid #c7c9cb;
  }
}

.text-area-feedback-form {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 8px;
  border-radius: 4px;
  border: 0.5px solid #d9d9d9;
}
