/* Transition
========================== */
.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 80px;
  height: 25px;
  padding: 3px;
  margin: 0;
  background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing: content-box;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
  &:checked {
    ~ {
      .switch-label {
        background: #e1b42b;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
          inset 0 0 3px rgba(0, 0, 0, 0.2);
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
      .switch-handle {
        left: 74px;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
  &:before {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }
  &:after {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
    content: attr(data-on);
    left: 11px;
    color: #ffffff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: linear-gradient(to bottom, #eeeeee, #ffffff);
    background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  }
}
.switch-flat {
  padding: 0;
  background: #fff;
  background-image: none;
  .switch-label {
    background: #fff;
    border: solid 2px #eceeef;
    box-shadow: none;
    &:after {
      color: #0088cc;
    }
  }
  .switch-handle {
    top: 6px;
    left: 6px;
    background: #dadada;
    width: 18px;
    height: 18px;
    box-shadow: none;
    &:before {
      background: #eceeef;
    }
  }
  .switch-input {
    &:checked {
      ~ {
        .switch-label {
          background: #fff;
          border-color: #1085c6;
        }
        .switch-handle {
          left: 55px;
          background: #1085c6;
          box-shadow: none;
        }
      }
    }
  }
}

.dataType {
  display: flex;
  div {
    display: flex;
    align-items: center;
  }
  label {
    font-size: 0.9rem !important;
  }
}

.amcharts-amexport-menu-level-0.amcharts-amexport-top {
  top: -20px !important;
}

.amcharts-amexport-item.amcharts-amexport-item-level-0 {
  opacity: 1 !important;
  background-color: #fff !important;
  width: 35px !important;
}

.amcharts-amexport-item.amcharts-amexport-item-level-0:hover {
  // border:1px solid rgb(43, 42, 42);
  background-color: rgb(211, 211, 211) !important;
}

.errorMsg {
  color: red;
}

.amcharts-range-selector-range-wrapper {
  display: none !important;
}

.date-container {
  position: relative;
  padding: 0.2em 1em 0px 0.4em;
  input {
    margin: 0.25em 1em 0px 0.3em;
    font-size: 12px;
    width: 90px;
    border-color: #e0e0e0;
    text-align: center;
    outline: none;
    width: 100%;
    padding: 7px 10px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1.5px solid #9e9e9e;
    overflow: visible;
  }
}

.calendar {
  position: absolute;
  z-index: 10;
  background: white;
  border: 1px solid #e1e4e8;
  border-radius: 5px;
  top: -45px;
  display: flex;
  align-items: center;
}

.rc-slider-track {
  height: 10px !important;
}

.rc-slider-tooltip-inner {
  min-width: 60px;
  background-color: #dadada;
  color: #686564;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #dadada;
}

.color.color {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 10px 10px 5px;
  border-bottom: 1px solid #ebebeb !important;
  border: none;
  box-sizing: border-box;
  margin-top: 22px;
  box-shadow: none;
  font-family: Open Sans, sans-serif;
  z-index: 1000;
}

.react-checkbox-tree {
  flex-direction: column !important;
}

#chartdata {
  max-height: 500px;
  overflow: auto;
}

#legend {
  margin: 0 0 50px 0;
}

#chartdata .chartTable {
  width: 100% !important;
  thead {
    tr th {
      padding: 8px 15px;
      text-transform: capitalize;
      text-align: right !important;
      font-size: 15px !important;
      &:nth-child(1) {
        min-width: 100px;
        text-align: left !important;
        left: 0;
        position: sticky !important;
        z-index: 20;
        top: 0;
      }
      &:nth-child(2) {
        text-align: left !important;
        @media only screen and (min-width: 767px) {
          left: 100px;
          position: sticky !important;
          z-index: 8;
          top: 0;
        }
      }
    }
  }
  tbody {
    tr td {
      padding: 8px 15px;
      color: $clr_grey;
      font-size: 15px !important;
      text-align: right !important;
      &:nth-child(1) {
        text-align: left !important;
        left: 0;
        position: sticky !important;
        z-index: 18;
      }
      &:nth-child(2) {
        text-align: left !important;
        @media only screen and (min-width: 767px) {
          left: 100px;
          position: sticky !important;
          z-index: 7;
          top: 0;
        }
      }
    }
  }
}

.DateRangePicker__CalendarSelection {
  background: $clr_lightblue;
  border: 1px solid $clr_lightblue;
}

// #chartdata .chartTable {
// 	thead {
// 	  @media only screen and (max-width: 1199px) {
// 		// background: $clr_lightblue;
// 	  }

// 	  tr {
// 		th {
// 		  @media only screen and (max-width: 1199px) {
// 			&:not(:nth-child(1)) {
// 				  border-right: solid 1px $clr_white;
// 			}
// 		  }
// 		  &:nth-child(1),
// 		  &:nth-child(2) {
// 			text-align: left;
// 		  }
// 		  &:nth-child(2),
// 		  &:nth-child(3),
// 		  &:nth-child(4) {
// 			// white-space: nowrap;
// 			white-space: normal !important;
// 		  }
// 		  &:nth-child(2),
// 		  &:nth-child(3),
// 		  &:nth-child(4) {
// 			@media only screen and (min-width: 769px) {
// 			  left: 0;
// 			  position: sticky !important;
// 			  z-index: 8;
// 			  top: 0;
// 			}
// 		  }
// 		}
// 	  }
// 	}
// 	tbody {
// 	  tr {
// 		td {
// 		  text-align: center;
// 		  &:not(:nth-child(1)) {
// 				border-right: solid 1px #dee2e8;
// 		  }
// 		  &:nth-child(2),
// 		  &:nth-child(3),
// 		  &:nth-child(4) {
// 			white-space: nowrap;
// 		  }
// 		  &:nth-child(2),
// 		  &:nth-child(3),
// 		  &:nth-child(4) {
// 			@media only screen and (min-width: 769px) {
// 			  left: 0;
// 			  position: sticky !important;
// 			  z-index: 7;
// 			  label {
// 				font-weight: 600;
// 			  }
// 			}
// 		  }
// 		}
// 		&:first-child {
// 		  td {
// 			&:not(:nth-child(1)) {
// 			  &:not(:nth-child(2)) {
// 				&:not(:nth-child(3)) {
// 				  &:not(:nth-child(4)) {
// 					border-top: solid 1px #eef0f3;
// 				  }
// 				}
// 			  }
// 			}
// 		  }
// 		}
// 		&:last-child {
// 		  td {
// 			&:not(:nth-child(1)) {
// 			  &:not(:nth-child(2)) {
// 				&:not(:nth-child(3)) {
// 				  &:not(:nth-child(4)) {
// 					border-bottom: solid 1px #eef0f3;
// 				  }
// 				}
// 			  }
// 			}
// 		  }
// 		}
// 	  }
// 	}
//   }
