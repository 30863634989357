.sidebar-item-div {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 12px 20px;
  align-items: center;
  color: #2e6ad2;
  cursor: pointer;
}

.sidebar-item-div:hover {
  background-color: #f1f8ff !important;
}

.sidebar-item-div:first-child {
  border-top: 1px solid#DEDEDE;
}

.sidebar-div {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  /* height: 80vh; */
}
